import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPayins(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payments/payins/', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayin(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/payments/payins/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayouts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payments/payouts/', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLockedPayouts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payments/locked-payouts-list/', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayout(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/payments/payouts/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createBatch(ctx, paymentsData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ipn/payouts/batches/create/', paymentsData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
